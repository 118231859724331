import React, { useEffect, useState } from 'react';

export default function LogoImage(props) {
  const [image, setImage] = useState('')

  async function loadImage() {
    const image_style = props.imageStyle;

    // Try to import image based on props.imageStyle or use default logo if
    // something goes wrong.
    // Also, these imports don't work by reference. For example: import(path_var)
    // won't work. So don't mind code duplication here.
    let imported_image;
    try {
      switch(image_style) {
        case 'big':
          imported_image = await import('../logo-big.webp');
          setImage(imported_image.default);
          break;
        default:
          imported_image = await import('../logo.webp');
          setImage(imported_image.default);
          break;
      }
    }
    catch (error) {
      // This image always exists.
      let imported_image = await import('../logo.webp');
      setImage(imported_image.default);
    }
  }

  useEffect(() => {
    loadImage()
  }, [])
  return (
    <img src={image} className={props.className ?? ''}
         alt={process.env.REACT_APP_COMPANY_NAME}/>
  );
}