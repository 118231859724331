import {Link, useLocation} from "react-router-dom";

import '../sass/header/header.scss';
import '../sass/header/header-logo.scss';
import '../sass/header/user-menu.scss';
import '../sass/header/basket-list-popup.scss';

import Menu from "./Menu";
import BurgerMenuButton from "./BurgetMenuPopup";
import BasketPopup from "./BasketPopup";
import {useAuth0} from "@auth0/auth0-react";
import * as Modules from "./Modules";
import Popup from "reactjs-popup";
import React from "react";
import LogoImage from './LogoImage';


function Header() {
    const {isAuthenticated} = useAuth0()
    const location = useLocation();

    return (
        <>{location.pathname !== '/login' &&
            <header className="header">
                <div className="container">
                    <div className="header_inner_grid">
                        <Link to='/' className="logo">
                          <LogoImage className="header-logo-img"/>
                        </Link>
                        <Menu/>
                        <div className='mobile-hamburger-menu'>
                            <BurgerMenuButton/>
                        </div>
                        <div className='icon-cont'>

                            {isAuthenticated &&
                                <BasketPopup/>}
                        </div>
                    </div>
                </div>
            </header>

        }
        </>
    )
}

export default Header