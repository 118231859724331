import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import logo from "../logo.webp";
import font from "../fonts/old-fonts/Montserrat-Regular.ttf"
import {productFields, titleField} from "../settings";
import wineWhite from '../assets/Wine-white.png';
import wineRed from '../assets/Wine-red.png';
import wineWhiteGrey from '../assets/Wine-white-grey.png';

Font.register({
  family: "Montserrat",
  format: "truetype",
  src: font
});

// Create styles
const styles = StyleSheet.create({
  page: {
    height: 842,
    backgroundColor: '#FFFFFF',
    fontFamily:  'Montserrat',
    fontSize: 7,
    marginHorizontal: 60,
    marginVertical: 15
  },
  header: {
    flexDirection: 'row'
  },
  content: {
    marginTop: 40,
    flexDirection: 'row'
  },
  footer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 40
  },
  headerLeft: {
    width: 400
  },
  headerRight: {
    width:245
  },
  logo: {
    width: 150,
  },
  contentLeft: {
    width:150
  },
  contentRight: {
    width:310,
    marginLeft:20
  },
  footerLeft: {
    width: 270
  },
  footerRight: {
    width: 200,
    alignContent: 'flex-end'
  },
  title: {
    fontSize: 16,
    paddingBottom: 20,
    fontWeight: 'Bold',
  },
  price: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 20,
    fontWeight: 'Bold',
  },
  subtitle: {
    fontSize: 9,
    width:310
  },
  subtitle2: {
    fontSize: 9,
    width:310,
    paddingBottom: 10,
    marginTop: -10
  },
  notes: {
    paddingTop: 10,
    width:310
  },
  fields: {
    paddingTop: 10
  },
  right: {
    textAlign: 'right'
  },
  field: {
    paddingRight: 5
  },
  lastField: {
  },
  listContainer: {

  },
  listElem: {
    flexDirection: "row",
    marginBottom: 4
  },
  listBullet: {
    marginHorizontal: 8,
  }
});

const Br = () => "\n";

// Create Document Component
// @TODO refactor, move some reused methods to separate files
export default function ProdPDF(props) {
  let product = props.product;

  let imageFile = /[^/]*$/.exec(product.Image1_URL)[0];
  // Get correct image for product.
  let image = process.env.REACT_APP_VINTNER_IMAGES && product.Image1_URL !== "" && !process.env.REACT_APP_URL_TO_IMAGES ? product.Image1_URL : product.Image1_URL !== "" && process.env.REACT_APP_URL_TO_IMAGES ? process.env.REACT_APP_URL_TO_IMAGES + imageFile
    : (product.Type == "White") ? wineWhite
      : (product.Type == "Red") ? wineRed : wineWhiteGrey;

  // Build arrays for Notes and Icon groups

  // Notes
  const notesGroup = [];
  const regex = /(<([^>]+)>)/ig;
  if (process.env.REACT_APP_NOTES_DISPLAY.includes('1') && product.Notes1 && product.Notes1 !== 'A') {
    notesGroup.push(product.Notes1.replace(regex, ''));
  }
  if (process.env.REACT_APP_NOTES_DISPLAY.includes('2') && product.Notes2 && product.Notes2 !== 'A') {
    notesGroup.push(product.Notes2.replace(regex, ''));
  }
  if (process.env.REACT_APP_NOTES_DISPLAY.includes('3') && product.Notes3 && product.Notes3 !== 'A') {
    notesGroup.push(product.Notes3.replace(regex, ''));
  }
  if (process.env.REACT_APP_NOTES_DISPLAY.includes('4') && product.Notes4 && product.Notes4 !== 'A') {
    notesGroup.push(product.Notes4.replace(regex, ''));
  }

  // Wine Making Notes
  var wineMakingNotes = "";
  if (process.env.REACT_APP_NOTES_DISPLAY.includes('W') && product.WineMakingNotes && product.WineMakingNotes !== 'A') {
    wineMakingNotes = product.WineMakingNotes.replace(regex, '');
  }
  // Type and Style
  const locationGroup = [];
  if (product.CountryDescription) {
    locationGroup.push(toTitles(product.CountryDescription));
  }
  if (product.Appellation && product.Appellation !== '0' && product.Appellation !== 'Not Set') {
    locationGroup.push(product.Appellation);
  }

  // Type and Style
  const typeGroup = [];
  if (product.Type && product.Type !== 'Not Set') {
    typeGroup.push(product.Type);
  }
  if (product.Style && product.Style !== 'Not Set') {
    typeGroup.push(product.Style);
  }
  if (product.ClosureStyle && product.ClosureStyle !== 'Not Set') {
    typeGroup.push(product.ClosureStyle);
  }

  // Organic / Biodynamic
  const organicGroup = [];
  if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Organic') {
    organicGroup.push('Organic');
  }
  const bioGroup = [];
  if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Biodynamic') {
    bioGroup.push('Biodynamic');
  }

  // Suitable for
  // TODO - In React can we loop through product.Allergen_SuitableFor* and condense this function?
  const suitableGroup = [];
  if (product.Allergen_SuitableForVegetarian == '1') {
    suitableGroup.push('Vegetarian');
  }
  if (product.Allergen_SuitableForVegan == '1') {
    suitableGroup.push('Vegan');
  }
  if (product.Allergen_SuitableForGlutenFreeDiet == '1') {
    suitableGroup.push('Gluten Free');
  }
  if (product.Allergen_SuitableForEggIntoleranceDiet == '1') {
    suitableGroup.push('Egg Intolerance');
  }
  if (product.Allergen_SuitableForLactoseIntoleranceDiet == '1') {
    suitableGroup.push('Lactose Intolerance');
  }
  if (product.Allergen_SuitableForLowFatDiet == '1') {
    suitableGroup.push('Low Fat Diet');
  }

  // Allergens
  // TODO - In React can we loop through product.Allergen_* and condense this function?
  // TODO - or do we manage this in the PHP backend controllers and send this array to React?
  const allergenGroup = [];
  if (product.Allergen_Sulphites == 1) {
    allergenGroup.push('Sulphites');
  }
  if (product.Allergen_Milk == 1) {
    allergenGroup.push('Milk');
  }
  if (product.Allergen_Egg == 1) {
    allergenGroup.push('Egg');
  }
  if (product.Allergen_Fish == 1) {
    allergenGroup.push('Nuts');
  }
  if (product.Allergen_Cereals == 1) {
    allergenGroup.push('Cereals');
  }
  if (product.Allergen_Nuts == 1) {
    allergenGroup.push('Nuts');
  }
  if (product.Allergen_Crustaceans == 1) {
    allergenGroup.push('Crustaceans');
  }
  if (product.Allergen_Molluscs == 1) {
    allergenGroup.push('Molluscs');
  }
  if (product.Allergen_Peanuts == 1) {
    allergenGroup.push('Peanuts');
  }
  if (product.Allergen_Soybeans == 1) {
    allergenGroup.push('Soybeans');
  }
  if (product.Allergen_Mustard == 1) {
    allergenGroup.push('Mustard');
  }
  if (product.Allergen_Sesame == 1) {
    allergenGroup.push('Sesame');
  }
  if (product.Allergen_Lupin == 1) {
    allergenGroup.push('Lupin');
  }

  // Food Suggestion
  // TODO - In React can we loop through product.Allergen_* and condense this function?
  // TODO - or do we manage this in the PHP backend controllers and send this array to React?
  const suggestionGroup = [];
  if (product.FoodSuggestion1 !== 'Not Set') {
    suggestionGroup.push(product.FoodSuggestion1);
  }
  if (product.FoodSuggestion2 !== 'Not Set') {
    suggestionGroup.push(product.FoodSuggestion2);
  }
  if (product.FoodSuggestion3 !== 'Not Set') {
    suggestionGroup.push(product.FoodSuggestion3);
  }
  if (product.FoodSuggestion4 !== 'Not Set') {
    suggestionGroup.push(product.FoodSuggestion4);
  }
  if (product.FoodSuggestion5 !== 'Not Set') {
    suggestionGroup.push(product.FoodSuggestion5);
  }

  console.log(product);

  function toTitles(s) {
    return s.replace(/\w\S*/g, function (t) {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  }

  function resolveAvailabilityMsg(product, type= 'available') {
    if (process.env.REACT_APP_SHOW_STOCK === "0") {
      return '';
    }

    let cases_stock = type === 'available' ? product.StockCases : product.StockOnOrderCases;
    let bottles_stock = type === 'available' ? product.StockBottles : product.StockOnOrderBottles;

    let cases_part = process.env.REACT_APP_UNIT_FLAG !== "1" || product.CaseSize === 1 ?
      `${cases_stock} ${product.CustomLabel ?? 'cases'}` :
      '';

    let bottles_part = ((process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && product.OnlySellMultiplesOf <= 1 && process.env.REACT_APP_UNIT_FLAG !== "2") || (!process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2"))  && product.CaseSize > 1  ?
      `${bottles_stock} bottles` :
      '';

    // Don't display 'available' word if cases and blocks are empty.
    if (cases_part.length < 1 && bottles_part.length < 1) {
      return '';
    }

    // When both are filled, add 'and'.
    if (cases_part.length > 0 && bottles_part.length > 1) {
      cases_part = cases_part + ' and ';
    }

    return `${cases_part}${bottles_part} ${type}`;
  }

  return (
    <Document title={product.Code + " " + product[titleField]}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Image style={styles.logo} src={logo}>
            </Image>
          </View>
          <View style={styles.headerRight}>
          </View>
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>{product[titleField]}</Text>
          <View style={styles.contentLeft}>
            <Image style={styles.logo} src={image}>
            </Image>
            {productFields.map((el, index) => el.name && !el.combined ? (

                <Text key={index}
                      style={styles.fields}>
                  {el.name === "Code" &&
                    <>
                      {/*<i*/}
                      {/*  className={`fas fa-icon ${el.iconClass}`}></i>*/}
                      {product['BinNo'] !== "" && process.env.REACT_APP_BIN === '1' ? (
                          <Text>{product['BinNo']}</Text>
                        ) :
                        <Text>{decodeURIComponent(product['Code'])}</Text>}
                    </>
                  }
                  {el.name !== "Code" &&
                    product[el.name] && product[el.name] !== "    " && product[el.name] !== " " &&
                    <>
                      {/*<i*/}
                      {/*className={`fas fa-icon ${el.iconClass}`}></i>*/}
                      <Text>{product[el.name]}</Text>
                    </>
                  }
                </Text>
              ) : (
                <Text key={index}
                      style={styles.fields}>
                  {/*<i*/}
                  {/*  className={`fas fa-icon ${el.iconClass}`}></i>*/}
                  <Text>
                    {el.combined.map((combinedEl, index) => (

                      <Text
                        key={index}>{product[combinedEl.name]}{index < el.combined.length - 1 && el.separator}{index === el.combined.length - 1 && el.append}</Text>
                    ))}
                  </Text>
                </Text>
              )
            )}
          </View>
          <View style={styles.contentRight}>
            {resolveAvailabilityMsg(product).length > 1 &&
              <Text style={styles.fields}>
                {resolveAvailabilityMsg(product)}
              </Text>}
            {process.env.REACT_APP_SHOW_STOCK === '3' && resolveAvailabilityMsg(product, 'on order').length > 1 &&
              <Text style={styles.fields}>
                {resolveAvailabilityMsg(product, 'on order')}
              </Text>}
            {process.env.REACT_APP_UNIT_FLAG !== "1" &&
              <Text style={styles.fields}>
               Case Nett:<Br/>{window.currency}{(Math.round((product.CasePrice * 1000) / 10) / 100).toFixed(2)}
              </Text>
            }
            {process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF ? product.OnlySellMultiplesOf <= 1 : !process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2" ? (
                <Text style={styles.fields}>Bottle Nett:{window.currency}{(Math.round((product.BottleNett * 1000) / 10) / 100).toFixed(2)}</Text>
              ) : (<></>)
            }
          </View>
          <View>
            {process.env.REACT_APP_NOTES_DISPLAY.includes('1') && typeof product.Notes1 === 'string' && product.Notes1 !== '' && product.Notes1 && product.Notes1 !== 'A' &&
              <Text>
                {process.env.REACT_APP_NOTES1_TITLE &&
                  <Text>{process.env.REACT_APP_NOTES1_TITLE}</Text>
                }
                <Text>{product.Notes1}</Text>
              </Text>
            }

            {process.env.REACT_APP_NOTES_DISPLAY.includes('2') && typeof product.Notes2 === 'string' && product.Notes2 !== '' && product.Notes2 && product.Notes2 !== 'A' &&
              <Text>{process.env.REACT_APP_NOTES2_TITLE &&
                <Text>{process.env.REACT_APP_NOTES2_TITLE}</Text>
              }
                <Text>{product.Notes2}</Text>
              </Text>
            }

            {process.env.REACT_APP_NOTES_DISPLAY.includes('3') && typeof product.Notes3 === 'string' && product.Notes3 !== '' && product.Notes3 && product.Notes3 !== 'A' &&
              <Text>{process.env.REACT_APP_NOTES3_TITLE &&
                <Text>{process.env.REACT_APP_NOTES3_TITLE}</Text>
              }
                <Text>{product.Notes3}</Text>
              </Text>
            }

            {process.env.REACT_APP_NOTES_DISPLAY.includes('4') && typeof product.Notes4 === 'string' && product.Notes4 !== '' && product.Notes4 && product.Notes4 !== 'A' &&
              <Text>{process.env.REACT_APP_NOTES4_TITLE &&
                <Text>{process.env.REACT_APP_NOTES4_TITLE}</Text>
              }
                <Text>{product.Notes4}</Text>
              </Text>
            }

            {process.env.REACT_APP_NOTES_DISPLAY.includes('W') && typeof product.WineTastingNotes === 'string' && product.WineTastingNotes !== '' && product.WineTastingNotes && product.WineTastingNotes !== 'A' &&
              <Text>{process.env.REACT_APP_NOTESW_TITLE &&
                <Text>{process.env.REACT_APP_NOTESW_TITLE}</Text>
              }
                <Text>{product.WineTastingNotes}</Text>
              </Text>
            }
            {process.env.REACT_APP_NOTES_DISPLAY.includes('S') && typeof product.ServingSuggestion === 'string' && product.ServingSuggestion !== '' && product.ServingSuggestion && product.ServingSuggestion !== 'A' &&
              <Text>{process.env.REACT_APP_NOTESS_TITLE &&
                <Text>{process.env.REACT_APP_NOTESS_TITLE}</Text>
              }
                <Text>{product.ServingSuggestion}</Text>
              </Text>
            }
          </View>
          <View>
            {process.env.REACT_APP_VINTAGE_DISPLAY === "1" && product.Vintage !== "    " && product.Vintage !== " " && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-calendar fa-2xl"></div>*/}
                {/*</div>*/}
                <Text>{product.Vintage}</Text>
              </Text>)}

            {process.env.REACT_APP_LOCATION_DISPLAY === "1" && product.CountryDescription.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div*/}
                {/*    className="fa-thin fa-earth-europe fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {locationGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_TYPE_DISPLAY === "1" && typeGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div*/}
                {/*    className="fa-thin fa-wine-glass fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {typeGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_WINEMAKER_DISPLAY === "1" && product.Winemaker.Name && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-grapes fa-2xl"></div>*/}
                {/*</div>*/}
                <Text>{product.Winemaker.Name}</Text>
              </Text>)}

            {process.env.REACT_APP_ABV_DISPLAY === "1" && product.AlcoholicPercentage && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-percent"></div>*/}
                {/*</div>*/}
                <Text>{product.AlcoholicPercentage}%</Text>
              </Text>)}

            {process.env.REACT_APP_ORGANIC_DISPLAY === "1" && organicGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-leaf fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {organicGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_BIO_DISPLAY === "1" && bioGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div*/}
                {/*    className="fa-thin fa-wine-glass fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {bioGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_ALLERGEN_DISPLAY === "1" && allergenGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div*/}
                {/*    className="fa-thin fa-circle-exclamation fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {allergenGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_SUITABLE_DISPLAY === "1" && suitableGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-wheat fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {suitableGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
                </View>
              </Text>)}

            {process.env.REACT_APP_SUGGESTION_DISPLAY === "1" && suggestionGroup.length && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div*/}
                {/*    className="fa-thin fa-fork-knife fa-2xl"></div>*/}
                {/*</div>*/}
                <View style={styles.listContainer}>
                  {suggestionGroup.map((item, i) => (
                    <View style={ styles.listElem } key={i}>
                      <Text style={ styles.listBullet }>•</Text>
                      <Text>{item}</Text>
                    </View>
                  ))}
              </View>
              </Text>)}

            {process.env.REACT_APP_EXCISE_DISPLAY === "1" && product.ExciseDescription && (
              <Text style={styles.fields}>
                {/*<div className={"circle"}>*/}
                {/*  <div className="fa-thin fa-file-invoice"></div>*/}
                {/*</div>*/}
                <Text>{product.ExciseDescription}</Text>
              </Text>)}
          </View>
        </View>
      </Page>
    </Document>
  );
}